import React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

import { useNavigate } from 'react-router-dom';
import { StyleCustomIconButton } from '../styles';
import { useSelector } from 'react-redux';
import LanguageSelector from '../_reusable/LanguageSelector';
import { Spacer } from '../_reusable/Spacer';

const NavBarContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const LanguageContainer = styled.div`
  display: flex;
  margin-right: ${({ navButton }) => (navButton ? '5rem' : '2rem')};

  @media (max-width: 50rem) {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
`;

function TopNavigationBar() {
  const navigate = useNavigate();
  const tenantData = useSelector((state) => state.tenant.tenantData);
  const projectData = useSelector((state) => state.project.projectData);

  const handleGoHome = () => {
    navigate(`/`);
  };

  return (
    <Toolbar
      style={{
        backgroundColor: tenantData?.tenant_color_combo_0_0,
        color: tenantData?.tenant_color_combo_0_1,
      }}
    >
      <NavBarContainer>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <StyleCustomIconButton
            aria-label="home"
            size="large"
            onClick={handleGoHome}
          >
            <HomeIcon />
          </StyleCustomIconButton>

          <Typography noWrap variant="h6">
            {tenantData?.tenant_name}
          </Typography>
        </div>
        <LanguageContainer navButton={projectData?.project_is_navbar_visible}>
          {projectData.project_is_support_multilanguage && (
            <>
              <div
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <LanguageSelector />
              </div>
            </>
          )}
        </LanguageContainer>
      </NavBarContainer>
    </Toolbar>
  );
}

export default TopNavigationBar;
