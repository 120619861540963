import { GET_SCREEN_RECABLE_SUFFIX } from '../_constants/urls';
import { get } from './dataSource';

const getRecableScreens = async ({ recableId, projectId, screenId }) => {
  return get(GET_SCREEN_RECABLE_SUFFIX, {
    params: {
      screen_id: screenId,
      recable_id: recableId,
      project_id: projectId,
    },
  });
};

export default getRecableScreens;
