export const AZURE_STAGE = process.env.REACT_APP_ENV || 'dev';

export const AZURE_HOST_PROTOCOL = 'https';

export const AZURE_HOST_STAGES = {
  dev: 'fa-ss-dev.azurewebsites.net',
  prod: 'fa-ss-prod.azurewebsites.net',
};
export const AZURE_HOST = AZURE_HOST_STAGES[AZURE_STAGE];

export const AZURE_CODE_STAGES = {
  dev: '6ebUI36VV8/n4gu077LKeCcQoxgNWH02XuP07OTphbKSjMuRLYkV9g==',
  prod: 'SR4EpAk01/4DgzeV00p4fbVWuWz1P7196CcHPRoGTFSacl4cjGFrMg==',
};
export const AZURE_CODE = AZURE_CODE_STAGES[AZURE_STAGE];

export const AZURE_SPEECH_SERVICE_KEYS = {
  prod: {
    key1: '36031a9b116b48f28ea38675facd14c5',
    key2: 'defd2b94c1104c5987e95ddb12d6869d',
    location: 'switzerlandnorth',
    endpoint:
      'https://switzerlandnorth.api.cognitive.microsoft.com/sts/v1.0/issuetoken',
  },
  dev: {
    key1: 'f91918fe1642460486859831e8a852c2',
    key2: '04697147ce5c464d83642bbb0292bffd',
    location: 'switzerlandnorth',
    endpoint:
      'https://switzerlandnorth.api.cognitive.microsoft.com/sts/v1.0/issuetoken',
  },
};

// export const BASE_AZURE_URL = `${process.env.REACT_APP_AZURE_HOST_PROTOCOL}://${process.env.REACT_APP_AZURE_HOST}/`;
export const BASE_AZURE_URL = `${AZURE_HOST_PROTOCOL}://${AZURE_HOST}/`;
export const API_SUFFIX = 'api/';
export const BASE_AZURE_API_URL = `${BASE_AZURE_URL}${API_SUFFIX}`;

const INFERENCE_SUFFIX = 'inference/';
const PROJECT_SUFFIX = 'project/';
const INFORMATION_STATIC_SUFFIX = 'information_static/';
const INFORMATION_SUFFIX = 'information/';
const TENANT_SUFFIX = 'tenant/';
const RECABLE_SUFFIX = 'recable/';
const RECABLE_REFERENCE_SUFFIX = 'recable_referenced/';
const LANGUAGE_SUFFIX = 'language/';
const SCREEN_ATTRIBUTES_SUFFIX = 'screen_attributes';
const SCREEN_RECABLE_SUFFIX = 'screen_recable';

export const GET_INFORMATION_STATIC_SUFFIX = INFORMATION_STATIC_SUFFIX;
export const GET_LANGUAGE_SUFFIX = LANGUAGE_SUFFIX;
export const GET_INFORMATION_SUFFIX = INFORMATION_SUFFIX;
export const GET_RECABLE_ID_SUFFIX = INFERENCE_SUFFIX;
export const GET_RECABLE_LIST_SUFFIX = RECABLE_SUFFIX;
export const GET_RECABLE_REFERENCE_SUFFIX = RECABLE_REFERENCE_SUFFIX;
export const GET_PROJECT_SUFFIX = PROJECT_SUFFIX;
export const GET_PROJECT_LIST_SUFFIX = PROJECT_SUFFIX;
export const GET_SCREEN_ATTRIBUTES_SUFFIX = SCREEN_ATTRIBUTES_SUFFIX;
export const GET_SCREEN_RECABLE_SUFFIX = SCREEN_RECABLE_SUFFIX;
export const GET_TENANT_SUFFIX = TENANT_SUFFIX;
