import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

function BottomCameraBar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const tenantData = useSelector((state) => state.tenant.tenantData);
  const staticInformation = useSelector(
    (state) => state.staticData.staticInformation
  );
  const { projectParamId } = useParams();

  const projectIdRedux = useSelector((state) => state.project.projectId);
  const languageCode = useSelector((state) => state.staticData.languageCode);

  const buttonText = !(
    pathname.includes('/recable') | pathname.includes('/screen')
  )
    ? staticInformation?.LP_button
    : staticInformation?.LP_button2;

  const handleOnCameraClick = () =>
    navigate(
      `/${languageCode}/project/${projectParamId || projectIdRedux}/camera`
    );

  return (
    <div
      style={{
        position: 'fixed',
        left: 0,
        bottom: 0,
        minHeight: '60px',
        width: '100%',
        display: 'flex',
        backgroundColor: tenantData?.tenant_color_combo_3_0,
        borderStyle: 'none',
        borderColor: '#eeeeee',
        color: tenantData?.tenant_color_combo_3_1,
        zIndex: 1,
      }}
    >
      <Button
        fullWidth
        size="large"
        variant="fullWidth"
        border="none"
        startIcon={<CameraIcon />}
        onClick={handleOnCameraClick}
      >
        <Typography
          variant="h6"
          textTransform="none"
          color={tenantData?.tenant_color_combo_3_1}
        >
          {buttonText}
        </Typography>
      </Button>
    </div>
  );
}

export default BottomCameraBar;
