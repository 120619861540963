import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#eeeeee',
      light: '#ffffff',
    },
    secondary: {
      main: '#FCE4BF',
    },
    tertiary: {
      main: '#F29400',
    },
  },
});

export default theme;
