import { createContext, useState } from 'react';
import { NotificationContainer } from 'react-notifications';
import { requestMediaPermissions } from 'mic-check';

import 'react-notifications/lib/notifications.css';
import { createNotification } from '../utils/notifications';

export const MediaContext = createContext();

export const MediaProvider = ({ children }) => {
  const [currentAudio, setCurrentAudio] = useState(null);
  const [audioPermissionGranted, setAudioPermissionGranted] = useState(null);

  const getAudioPermission = () => {
    requestMediaPermissions({ audio: true })
      .then(() => {
        setAudioPermissionGranted(true);
        createNotification(
          'success',
          'Permission granted',
          'Audio permission granted, you can now record yourself.'
        )();
      })
      .catch((e) => {
        setAudioPermissionGranted(false);
        createNotification(
          'error',
          'Permission request failed',
          'Audio permission not granted.'
        )();
      });
  };

  return (
    <MediaContext.Provider
      value={{
        currentAudio,
        setCurrentAudio,
        permissionGranted: audioPermissionGranted,
        getPermission: getAudioPermission,
      }}
    >
      {children}
      <NotificationContainer />
    </MediaContext.Provider>
  );
};
