import { ProjectActionTypes } from './project.types';
import getProject from '../../_api/getProject';
import getRecableList from '../../_api/getRecableList';
import getProjectList from '../../_api/getProjectList';

export const setProjectList = (projects) => ({
  type: ProjectActionTypes.SET_PROJECT_LIST,
  payload: projects,
});

export const setProjectId = (id) => ({
  type: ProjectActionTypes.SET_PROJECT_ID,
  payload: id,
});

export const setProjectData = (items) => ({
  type: ProjectActionTypes.SET_PROJECT_DATA,
  payload: items,
});

export const clearProject = () => ({
  type: ProjectActionTypes.CLEAR_PROJECT,
});

export const setProjectLoadError = (err) => ({
  type: ProjectActionTypes.SET_ERROR,
  payload: err,
});

export const loadProject = (projectId) => async (dispatch, getState) => {
  try {
    const { languageCode } = getState().staticData;
    const projectData = await getProject({
      projectId: projectId,
      langId: languageCode,
    });
    dispatch(setProjectData(projectData.data.result));
  } catch (err) {
    console.error(err);
    dispatch(
      setProjectLoadError({ message: `${err}`, status: err?.response?.status })
    );
  }
};

export const loadProjectRecables =
  (projectId) => async (dispatch, getState) => {
    const { languageCode } = getState().staticData;
    const { tenantId } = getState().tenant;
    try {
      const recables = await getRecableList({
        langId: languageCode,
        tenantId: tenantId,
        projectId: projectId,
      });
      dispatch(setProjectData({ items: recables.data.result }));
    } catch (err) {
      console.error(err);
      dispatch(
        setProjectLoadError({
          message: `${err}`,
          status: err?.response?.status,
        })
      );
    }
  };

export const loadProjectList = () => async (dispatch, getState) => {
  const { languageCode } = getState().staticData;
  const { tenantId } = getState().tenant;
  try {
    const projects = await getProjectList({
      langId: languageCode,
      tenantId: tenantId,
    });
    dispatch(setProjectList(projects.data.result));
  } catch (err) {
    console.error(err);
    dispatch(
      setProjectLoadError({ message: `${err}`, status: err?.response?.status })
    );
  }
};
