import { Text } from '../components/_reusable/Text';
import React from 'react';

export const getText = ({ text, color, variant = null }) => {
  try {
    const nodes = JSON.parse(text);
    if (nodes.length) {
      return <ListMarkdownText color={color} nodes={nodes} variant={variant} />;
    } else {
      return <MarkdownText node={nodes} color={color} variant={variant} />;
    }
  } catch (e) {
    return <Text variant={variant || 'normal'} color={color} text={text} />;
  }
};
export const ListMarkdownText = ({ nodes, ...restProps }) => {
  return (
    <>
      {nodes.map((text, index) => (
        <MarkdownText key={`text${text}-${index}`} node={text} {...restProps} />
      ))}
    </>
  );
};

export const MarkdownText = ({
  node,
  variant = null,
  color = 'textPrimary',
  ...restProps
}) => {
  let style = {};
  if (node.text !== undefined) {
    if (node.bold && !restProps.variant) {
      style = { ...style, fontWeight: 'bold' };
    }
    if (node.italic) {
      style = { ...style, fontStyle: 'italic' };
    }
    if (node.code) {
      // textNode = <code>{textNode}</code>;
    }
    if (node.underline) {
      style = { ...style, textDecoration: 'underline' };
    }
    return (
      <Text
        text={node.text}
        variant={variant}
        {...style}
        color={color}
        isSpan={true}
      />
    );
  }
  const children = node.children.map((n, index) => (
    <MarkdownText key={`${index}-n`} node={n} variant={variant} color={color} />
  ));
  style = { ...style, textAlign: node.align };

  switch (node.type) {
    case 'bulleted-list':
      return <ul style={style}>{children}</ul>;
    case 'heading-one':
      return <h1 style={style}>{children}</h1>;
    case 'heading-two':
      return <h2 style={style}>{children}</h2>;
    case 'list-item':
      return <li style={style}>{children}</li>;
    case 'numbered-list':
      return <ol style={style}>{children}</ol>;
    case 'quote':
      return (
        <blockquote style={style}>
          <div>{children}</div>
        </blockquote>
      );
    case 'paragraph':
      return <div style={{ ...style, marginBottom: 10, minHeight: 1 }}>{children}</div>;
    case 'link':
      return (
        <a style={style} href={`${node.url}`}>
          {children}
        </a>
      );
    default:
      return children;
  }
};
