import { GET_SCREEN_ATTRIBUTES_SUFFIX } from '../_constants/urls';
import { get } from './dataSource';

const getVizRecable = async ({ langId, screenId }) => {
  return get(GET_SCREEN_ATTRIBUTES_SUFFIX, {
    params: { screen_id: screenId, lang_id: langId },
  });
};

export default getVizRecable;
