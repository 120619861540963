import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TemplateText({
  text,
  color = 'textPrimary',
  align = 'left',
  fontSize = '16px',
  fontWeight = '450',
  component = 'span',
  letterSpacing = '0px',
  isSpan = false,
  ...restProps
}) {
  return (
    <Typography
      align={align}
      component={component}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      letterSpacing={letterSpacing}
      {...restProps}
    >
      {isSpan ? (
        <>{text}</>
      ) : (
        <Box marginTop="5px" marginBottom="5px">
          <div style={{ whiteSpace: 'pre-wrap' }}>{text}</div>
        </Box>
      )}
    </Typography>
  );
}

function Text({
  text,
  color = 'textPrimary',
  variant = 'normal',
  isSpan = false,
  ...restProps
}) {
  let fontSize = 16;
  let fontWeight = 'normal';
  let letterSpacing = null;

  if (['massive', 'caption', 'detailBold', 'bold'].includes(variant)) {
    fontWeight = 'bold';
  }
  switch (variant) {
    case 'massive':
      fontSize = 24;
      letterSpacing = 1;
      break;
    case 'caption':
      fontSize = 18;
      break;
    case 'large':
      fontSize = 18;
      break;
    case 'detail':
      fontSize = 16;
      break;
    case 'detailBold':
      fontSize = 16;
      break;
    default:
      fontSize = 16;
  }
  if (variant === 'boldTextCard') {
    return (
      <Typography
        align="justify"
        component="span"
        fontWeight="600"
        variant="h6"
        color={color}
        {...restProps}
      >
        <Box
          marginTop="10px"
          marginBottom="5px"
          lineHeight="normal"
          textAlign="left"
        >
          {text}
        </Box>
      </Typography>
    );
  }

  if (variant === 'textCard') {
    return (
      <Typography align="justify" component="span" color="#959595">
        <Box marginBottom="15px" lineHeight="normal" textAlign="left">
          {text}
        </Box>
      </Typography>
    );
  }

  if (variant === 'imageTextCard') {
    return (
      <Typography
        align="center"
        color={color}
        fontWeight="600"
        variant="h5"
        gutterBottom
        component="span"
      >
        <Box marginRight="48px" marginLeft="48px">
          {text}
        </Box>
      </Typography>
    );
  }
  if (variant === 'headlineCard') {
    return (
      <Typography
        align="center"
        color={color}
        fontWeight="600"
        variant="h5"
        gutterBottom
        component="span"
      >
        <Box marginTop="30px" marginBottom="48px" lineHeight="normal">
          {text}
        </Box>
      </Typography>
    );
  }

  if (variant === 'headline') {
    return (
      <Typography
        align="center"
        color={color}
        variant="h6"
        gutterBottom
        component="span"
      >
        <Box marginBottom="7.5px" lineHeight="normal">
          {text}
        </Box>
      </Typography>
    );
  }
  return (
    <TemplateText
      isSpan={isSpan}
      text={text}
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      letterSpacing={letterSpacing}
      {...restProps}
    />
  );
}

function TertiaryHeadline({ text, color = 'textPrimary' }) {
  return (
    <Typography
      align="center"
      color={color}
      fontSize="14px"
      gutterBottom
      component="span"
    >
      <Box marginBottom="7.5px" lineHeight="normal">
        {text}
      </Box>
    </Typography>
  );
}

function IntroText({ text }) {
  return (
    <Typography align="justify" component="span">
      {text}
    </Typography>
  );
}

function SecondaryText({ text }) {
  return (
    <Typography align="justify" color="primary" component="span">
      {text}
    </Typography>
  );
}

function TertiaryText({ text, color = '#646260' }) {
  return (
    <Typography align="left" color={color} component="span" fontSize="14px">
      {text}
    </Typography>
  );
}

/* Styles for >> Card */

export { Text, TertiaryHeadline, IntroText, SecondaryText, TertiaryText };
