const createInteractiveSpotObject = (attributes) => {
  const spot = {
    flavour: '',
    bgColor: 'transparent',
    unit: '',
    coords: null,
    message: '',
    showRectangle: false,
    toggleRectangleOnClick: false,
    showMessage: false,
    toggleMessageOnClick: false,
    cntTouch: false,
    touched: false,
    innerContainer: null,
  };
  attributes.forEach((attr) => {
    const { screen_attribute_value, screen_attribute_type_codename } = attr;
    switch (screen_attribute_type_codename) {
      case 'list_coordinate_pairs':
        spot.unit = 'percentage';
        spot.coords = JSON.parse(screen_attribute_value);
        break;
      case 'str_tag':
        spot.message = screen_attribute_value;
        break;
      case 'is_tagvisiblebydefault':
        spot.showMessage = screen_attribute_value === '1';
        break;
      case 'is_tagappearonclick':
        spot.toggleMessageOnClick = screen_attribute_value === '1';
        break;
      case 'is_rectanglevisiblebydefault':
        spot.showRectangle = screen_attribute_value === '1';
        break;
      case 'is_rectangleappearonclick':
        spot.toggleRectangleOnClick = screen_attribute_value === '1';
        break;
      case 'is_contributetocounter':
        spot.cntTouch = screen_attribute_value === '1';
        break;
      default:
        break;
    }
  });
  return spot;
};

const createInteractiveImage = (attributes) => {
  const config = {
    image: '',
  };

  attributes.forEach((attr) => {
    const { screen_attribute_value, screen_attribute_type_codename } = attr;
    switch (screen_attribute_type_codename) {
      case 'url_picture_clickable':
        config.image = screen_attribute_value;
        break;
      default:
        break;
    }
  });
  return config;
};

const createQuestions = (artefacts) => {
  const query = {
    question: {},
    answers: [],
  };
  artefacts.forEach((artefact) => {
    const { artefact_codename, artefact_attribute } = artefact;
    switch (artefact_codename) {
      case 'question':
        query.question = artefact_attribute[0].screen_attribute_value;
        break;
      case 'answer':
        const answer = {};
        artefact_attribute.forEach((attr) => {
          if (attr.screen_attribute_type_codename === 'str_answer_text') {
            answer.description = attr.screen_attribute_value;
          } else if (
            attr.screen_attribute_type_codename === 'is_answer_correct'
          ) {
            answer.correct = attr.screen_attribute_value === '1';
          }
          answer.selected = false;
        });
        query.answers.push(answer);
        break;
      default:
        break;
    }
  });
  return query;
};

const createMCQEntry = (guests) => {
  let questions = [];
  const { artefact_codename, artefact_guests } = guests[0];
  switch (artefact_codename) {
    case 'question-and-answer':
      questions.push(createQuestions(artefact_guests));
      break;
    default:
      break;
  }
  return questions;
};

export const processArtefact = (artefact) => {
  const codename = artefact.artefact_codename;
  const attributes = artefact.artefact_attribute;
  const vizArtefact = {
    component: '',
    spots: [],
    descriptionText: [],
    descriptionAudio: [],
    descriptionImage: [],
    dialog: {},
    backgroundImage: null,
    gameSettings: {
      successScreen: {},
      failureScreen: {},
    },
  };
  switch (codename) {
    case 'no-game-artefact':
      vizArtefact.component = 'NoGame';
      break;
    case 'picture-clickable-artefact':
      vizArtefact.component = 'GameImage';
      break;
    case 'question-and-answer-artefact':
      vizArtefact.component = 'GameMCQ';
      break;
    case 'speech-to-text-artefact':
      vizArtefact.component = 'GameDialog';
      break;
    default:
      break;
  }

  attributes.forEach((attr) => {
    const { screen_attribute_type_codename, screen_attribute_value } = attr;
    switch (screen_attribute_type_codename) {
      case 'is_automatically_forward_to_next_screen':
        vizArtefact.automaticallyForwardToNextScreen =
          screen_attribute_value === '1';
        break;
      case 'num_automatically_forward_after_x_seconds_to_next_screen':
        vizArtefact.automaticallyForwardToNextScreenAfter =
          screen_attribute_value;
        break;
      case 'url_picture_background':
        vizArtefact.backgroundImage = screen_attribute_value;
        break;
      case 'str_show_tags_relative_to_picture':
        vizArtefact.gameSettings.showTagsRelativeToPicture =
          screen_attribute_value;
        break;
      case 'num_max_trials':
        vizArtefact.gameSettings.maxTrials = screen_attribute_value;
        break;
      case 'str_message_failure':
        vizArtefact.gameSettings.failureMessage = screen_attribute_value;
        break;
      case 'str_message_success':
        vizArtefact.gameSettings.successMessage = screen_attribute_value;
        break;
      case 'is_highlight_on_trial':
        vizArtefact.gameSettings.highlightOnTrial =
          screen_attribute_value === 'true';
        break;
      case 'is_show_screen_success':
        vizArtefact.gameSettings.successScreen.show =
          screen_attribute_value === 'true';
        break;
      case 'str_screen_success_message':
        vizArtefact.gameSettings.successScreen.message = screen_attribute_value;
        break;
      case 'is_show_screen_failure':
        vizArtefact.gameSettings.failureScreen.show =
          screen_attribute_value === 'true';
        break;
      case 'str_screen_failure_message':
        vizArtefact.gameSettings.failureScreen.message = screen_attribute_value;
        break;
      default:
        break;
    }
  });

  let description = {};

  artefact.artefact_guests.forEach((guest) => {
    const { artefact_codename, artefact_attribute, artefact_guests } = guest;
    switch (artefact_codename) {
      case 'picture-clickable':
        vizArtefact.config = { ...createInteractiveImage(artefact_attribute) };
        break;
      case 'rectangle-clickable':
        vizArtefact.spots.push(createInteractiveSpotObject(artefact_attribute));
        break;
      case 'question-and-answer-list':
        vizArtefact.questions = createMCQEntry(artefact_guests);
        break;
      case 'text-summary-count-correct':
        vizArtefact.resultText = artefact_attribute.filter(
          (e) => e.screen_attribute_type_codename === 'str_text_count_corrects'
        )[0].screen_attribute_value;
        vizArtefact.showResultTextAfterClearify =
          artefact_attribute.filter(
            (e) =>
              e.screen_attribute_type_codename ===
              'is_only_visible_after_clearifying'
          )[0].screen_attribute_value === '1';
        break;
      case 'button-clearify':
        vizArtefact.clearifyButton =
          artefact_attribute[0].screen_attribute_value;
        break;
      case 'text-single':
        description = {};
        artefact_attribute.forEach((attr) => {
          const { screen_attribute_type_codename, screen_attribute_value } =
            attr;
          switch (screen_attribute_type_codename) {
            case 'str_text':
              description.text = screen_attribute_value;
              break;
            case 'str_text_type':
              description.type = screen_attribute_value;
              break;
            case 'num_margin_pixel_bottom':
              description.marginBottom = screen_attribute_value;
              break;
            default:
              break;
          }
        });
        vizArtefact.descriptionText.push(description);
        break;
      case 'audio-single':
        description = {};
        artefact_attribute.forEach((attr) => {
          const { screen_attribute_type_codename, screen_attribute_value } =
            attr;
          switch (screen_attribute_type_codename) {
            case 'url_audio':
              description.url = screen_attribute_value;
              break;
            case 'is_play_automatically':
              description.playAutomatically = screen_attribute_value === 'true';
              break;
            case 'is_show_audio_player':
              description.showAudioPlayer = screen_attribute_value === 'true';
              break;
            case 'num_margin_pixel_bottom':
              description.marginBottom = screen_attribute_value;
              break;
            default:
              break;
          }
        });
        vizArtefact.descriptionAudio.push(description);
        break;
      case 'picture-single':
        description = {};
        artefact_attribute.forEach((attr) => {
          const { screen_attribute_type_codename, screen_attribute_value } =
            attr;
          switch (screen_attribute_type_codename) {
            case 'str_text':
              description.description = screen_attribute_value;
              break;
            case 'url_picture':
              description.url = screen_attribute_value;
              break;
            case 'num_max_width':
              description.numMaxWidth = screen_attribute_value;
              break;
            case 'num_margin_pixel_bottom':
              description.marginBottom = screen_attribute_value;
              break;
            default:
              break;
          }
        });
        vizArtefact.descriptionImage.push(description);
        break;
      case 'button-next-screen':
        description = {};
        artefact_attribute.forEach((attr) => {
          const { screen_attribute_type_codename, screen_attribute_value } =
            attr;
          switch (screen_attribute_type_codename) {
            case 'str_button_text':
              description.text = screen_attribute_value;
              break;
            case 'is_only_visible_after_clearifying':
              description.showNextButtonAfterClearify =
                screen_attribute_value === 'true';
              break;
            case 'num_margin_pixel_bottom':
              description.marginBottom = screen_attribute_value;
              break;
            case 'int_next_screen_id':
              description.nextScreenId = screen_attribute_value;
              break;
            default:
              break;
          }
        });
        vizArtefact.nextButton = description;
        break;
      case 'button-record':
        vizArtefact.showButtonRecord = true;
        break;
      case 'speech-bubble-section':
        artefact_attribute.forEach((attr) => {
          const { screen_attribute_type_codename, screen_attribute_value } =
            attr;
          switch (screen_attribute_type_codename) {
            case 'is_color_alternating':
              vizArtefact.dialog.alternateColors =
                screen_attribute_value === '1';
              break;
            case 'str_color0':
              vizArtefact.dialog.first = screen_attribute_value;
              break;
            case 'str_color1':
              vizArtefact.dialog.second = screen_attribute_value;
              break;
            default:
              break;
          }
        });
        break;
      default:
        break;
    }
  });
  return vizArtefact;
};
