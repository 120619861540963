import React, { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './routes.json';
import AppLayout from '../components/AppLayout';

const componentMap = {
  AppLayout,
  LandingPage: lazy(() => import('../components/LandingPage/index')),
  Camera: lazy(() => import('../components/Camera/index')),
  CameraWarningScreen: lazy(() =>
    import('../components/Camera/PermissionErrorScreen')
  ),
  Project: lazy(() => import('../components/Project/index')),
  Recable: lazy(() => import('../components/Recable/index')),
  RecableScreen: lazy(() => import('../components/Recable/RecableScreen')),
  ListTopics: lazy(() => import('../components/Project/ListTopics')),
  NotFound: lazy(() => import('../components/NotFound/index')),
};

const mapRoutes = (routesObj) =>
  routesObj.map(({ path, element, children }) => {
    const Element = element ? componentMap[element] : undefined;
    return {
      path,
      element: Element ? <Element /> : undefined,
      children: children ? mapRoutes(children) : undefined,
    };
  });

const Routes = () => {
  const mappedRoutes = mapRoutes(routes);
  return useRoutes(mappedRoutes);
};

export default Routes;
