import { ProjectActionTypes } from './project.types';

const INITIAL_STATE = {
  projectId: null,
  projectData: {
    items: null,
  },
  projectList: null,
  error: null,
};

export const projectReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProjectActionTypes.SET_PROJECT_LIST:
      return { ...state, projectList: action.payload };
    case ProjectActionTypes.SET_PROJECT_ID:
      return { ...state, projectId: action.payload };
    case ProjectActionTypes.SET_PROJECT_DATA:
      return {
        ...state,
        projectData: { ...state.projectData, ...action.payload },
      };
    case ProjectActionTypes.CLEAR_PROJECT:
      return {
        ...state,
        projectData: {
          project_is_navbar_visible:
            state.projectData.project_is_navbar_visible,
          items: null,
        },
      };
    case ProjectActionTypes.SET_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
