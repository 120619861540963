import { GET_PROJECT_SUFFIX } from '../_constants/urls';
import { get } from './dataSource';

// get single project data
const getProject = async ({ langId, projectId }) => {
  return await get(`${GET_PROJECT_SUFFIX}${projectId}`, {
    params: { lang_id: langId },
  });
};

export default getProject;
