import { GET_RECABLE_LIST_SUFFIX } from '../_constants/urls';
import { get } from './dataSource';

// get all projects
const getRecableList = async ({ langId, tenantId, projectId }) => {
  return await get(`${GET_RECABLE_LIST_SUFFIX}`, {
    params: {
      clientId: 'default',
      lang_id: langId,
      tenant_id: tenantId,
      project_id: projectId,
    },
  });
};

export default getRecableList;
