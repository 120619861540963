import { styled } from '@mui/material/styles';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';

const StyleBody = styled('div')(() => ({
  display: 'flex',
  margin: 'auto',
  flexDirection: 'column',
}));

const StyleCard = styled(Card)(({ theme, color }) => ({
  borderRadius: '0',
  backgroundColor: color,
  border: 'none',
  boxShadow: 'none',
}));

const StyleCardGrid = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  backgroundColor: theme.palette.primary,
}));

const StyleCardMedia = styled(({ theme }) => ({
  paddingTop: '56.25%', // 16:9
}));

const StyleCardMediaSquare = styled(CardMedia)(({ theme }) => ({
  paddingTop: '100%', // 16:9
}));

const StyleCustomIconButton = styled(IconButton)(({ theme }) => ({
  '&.MuiButtonBase-root': { color: 'black' },
}));

export {
  StyleBody,
  StyleCard,
  StyleCardGrid,
  StyleCardMedia,
  StyleCardMediaSquare,
  StyleCustomIconButton,
};
