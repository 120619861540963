import React, { useEffect } from 'react';
import LogRocket from 'logrocket';
import { v4 as uuidv4 } from 'uuid';

import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import theme from './theme';
import Routes from './routes';
import { MediaProvider } from './_providers/MediaProvider';
import { store } from './redux/store';

LogRocket.init('cqj1xm/shaire-app');

function App() {
  useEffect(() => {
    let sessionId = window.localStorage.getItem('shaire_session_id');
    if (!sessionId) {
      sessionId = `${Date.now()}-${uuidv4()}`;
    }
    window.localStorage.setItem('shaire_session_id', sessionId);
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <MediaProvider>
            <BrowserRouter>
              <CssBaseline />
              <Routes />
            </BrowserRouter>
          </MediaProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
