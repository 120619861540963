import { GET_LANGUAGE_SUFFIX } from '../_constants/urls';
import { get } from './dataSource';

// loads a json containing a list of all languages incl. their infos
// including the name (shown in dropdown) and the lang_id (important
// for other API calls)
const getLanguageData = async () => {
  return await get(`${GET_LANGUAGE_SUFFIX}`, { params: {} });
};

export default getLanguageData;
