import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function LanguageSelector() {
  const navigate = useNavigate();

  const tenantData = useSelector((state) => state.tenant.tenantData);
  const languageCode = useSelector((state) => state.staticData.languageCode);
  const languageDict = useSelector((state) => state.staticData.languageDict);
  const projectId = useSelector((state) => state.project.projectId);

  const handleChange = (event) => {
    const langCode = event.target.value;
    navigate(`/${langCode}/project/${projectId}/`);
  };

  return (
    <FormControl
      variant="filled"
      sx={{
        minWidth: 180,
        height: 50,
        padding: 0,
      }}
      style={{
        backgroundColor: tenantData?.tenant_color_combo_1_3,
        borderRadius: 3,
        padding: 0,
        borderColor: tenantData?.tenant_color_combo_1_3,
      }}
    >
      <Select value={languageCode} onChange={handleChange} style={{ flex: 1 }}>
        {Object.keys(languageDict).map((lan) => {
          return (
            <MenuItem
              key={languageDict[lan]}
              value={languageDict[lan]}
              style={{
                backgroundColor:
                  languageCode === languageDict[lan]
                    ? tenantData?.tenant_color_combo_1_3
                    : tenantData?.tenant_color_combo_4_0,
              }}
            >
              <Typography
                style={{
                  color:
                    languageCode === languageDict[lan]
                      ? tenantData?.tenant_color_combo_1_2
                      : tenantData?.tenant_color_combo_4_1,
                }}
              >
                {lan}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default LanguageSelector;
