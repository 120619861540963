import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { MenuItem } from './MenuItem';
import { Text } from '../_reusable/Text';
import { Spacer } from '../_reusable/Spacer';

import { useSelector } from 'react-redux';
import LanguageSelector from '../_reusable/LanguageSelector';

const Separator = styled.div`
  margin-top: 30px;
  height: 3px;
  width: 100%;
  background-color: ${({ color }) => color};
`;

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    visibility: 'visible',
    display: 'block',
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
    visibility: 'hidden',
    display: 'none',
  },
};

const LanguageContainer = styled.div`
  @media (min-width: 50rem) {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
  margin-left: -15px;
`;

export function Navigation({ toggleMenu }) {
  const projectId = useSelector((state) => state.project.projectId);
  const projectData = useSelector((state) => state.project.projectData);
  const projectList = useSelector((state) => state.project.projectList);
  const tenantData = useSelector((state) => state.tenant.tenantData);
  const languageCode = useSelector((state) => state.staticData.languageCode);
  const staticInformation = useSelector(
    (state) => state.staticData.staticInformation
  );

  const itemIds = [];
  const [navItems, setNavItems] = useState([...itemIds]);
  const [otherNavItems, setOtherNavItems] = useState([]);

  const itemFilterArtworkOnly = (item) => item.recable_type_name === 'artwork';
  const itemFilterArtistOnly = (item) => item.recable_type_name === 'creator';
  const itemFilterTopicOnly = (item) => item.recable_type_name === 'topic';
  const itemFilterShowInNavigation = (item) =>
    item.recable_is_show_in_navigation === true;

  useEffect(() => {
    if (staticInformation) {
      if (projectData) {
        const items = projectData.items ? projectData.items : [];
        const recableArtworks = items
          .filter(itemFilterShowInNavigation)
          .filter(itemFilterArtworkOnly)
          .map((item) => ({
            text: item.name,
            page: `/${languageCode}/project/${projectId}/recable/${item.recable_id}/recableType/artwork`,
            visible: true,
          }));
        const recableArtists = items
          .filter(itemFilterShowInNavigation)
          .filter(itemFilterArtistOnly)
          .map((item) => ({
            text: item.name,
            page: `/${languageCode}/project/${projectId}/recable/${item.recable_id}/recableType/creator`,
            visible: true,
          }));
        const recableTopics = items
          .filter(itemFilterShowInNavigation)
          .filter(itemFilterTopicOnly)
          .map((item) => ({
            text: item.name,
            page: `/${languageCode}/project/${projectId}/recable/${item.recable_id}/recableType/topic`,
            visible: true,
          }));
        setNavItems([
          {
            type: 'launch',
            typeHeader: 'Headline_nav_launch',
            isCanBeToggled: false,
            isToggled: false,
            isVisible: true,
            children: [
              {
                text: staticInformation?.Title_page_launch,
                page: '/',
                visible: true,
              },
            ],
          },
          {
            type: 'topic',
            typeHeader: 'Headline_nav_topic',
            isVisible:
              projectData?.project_is_navbar_include_topic_section | false,
            isCanBeToggled: true,
            isToggled: true,
            children: [
              {
                text: staticInformation.Headline_overview,
                page: `/${languageCode}/project/${projectId}/listTopics/topic`,
                visible: projectData.project_is_navbar_include_topic_overview,
              },
              ...recableTopics,
            ],
          },
          {
            type: 'artists',
            typeHeader: 'Headline_nav_artist',
            isVisible:
              projectData?.project_is_navbar_include_artist_section | false,
            isCanBeToggled: true,
            isToggled: true,
            children: [
              {
                text: staticInformation.Headline_overview,
                page: `/${languageCode}/project/${projectId}/listTopics/creator`,
                visible: projectData.project_is_navbar_include_artist_overview,
              },
              ...recableArtists,
            ],
          },
          {
            type: 'artworks',
            typeHeader: 'Headline_nav_artwork',
            isVisible:
              projectData?.project_is_navbar_include_artwork_section | false,
            isCanBeToggled: true,
            isToggled: true,
            children: [
              {
                text: staticInformation.Headline_overview,
                page: `/${languageCode}/project/${projectId}/listTopics/artwork`,
                visible: projectData.project_is_navbar_include_artwork_overview,
              },
              ...recableArtworks,
            ],
          },
        ]);
      }

      if (projectList) {
        const otherProject = projectList.filter(
          (project) => `${project.project_id}` !== `${projectId}`
        );

        const otherProjectLinks = otherProject.map((project) => ({
          text: project.project_name,
          page: `/${languageCode}/project/${project.project_id}`,
        }));
        setOtherNavItems([...otherProjectLinks]);
      }
    }
  }, [projectId, projectData, projectList, staticInformation, languageCode]);

  const getMenuItemFromChildren = function (item_bucket) {
    const list_item_bucket_children = item_bucket.children;
    return list_item_bucket_children
      .filter((item) => item.visible)
      .map((item, i) => (
        <MenuItem i={i} key={i} {...item} toggleMenu={toggleMenu} />
      ));
  };

  return (
    <motion.ul
      variants={variants}
      style={{
        padding: 25,
        position: 'absolute',
        top: 25,
        zIndex: 15,
        width: 260,
      }}
    >
      <div>
        <LanguageContainer navButton={projectData?.project_is_navbar_visible}>
          {projectData.project_is_support_multilanguage && (
            <>
              <div
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <LanguageSelector />
              </div>
            </>
          )}
        </LanguageContainer>
        {navItems &&
          staticInformation &&
          navItems
            .filter((item_bucket) => item_bucket.isVisible)
            .map(
              (item_bucket, i) =>
                item_bucket.children.length >= 1 && (
                  <div key={item_bucket.type}>
                    {projectData?.project_is_show_title_separator && (
                      <Separator color={tenantData?.tenant_color_combo_2_1} />
                    )}
                    <Text
                      variant={'bold'}
                      color={tenantData?.tenant_color_combo_2_1}
                      text={staticInformation[item_bucket.typeHeader]}
                    />
                    <Spacer position="bottom" size="15" />
                    {getMenuItemFromChildren(item_bucket)}
                  </div>
                )
            )}
      </div>

      {otherNavItems.length > 0 && staticInformation && (
        <>
          {projectData?.project_is_show_title_separator && (
            <>
              <Separator color={tenantData?.tenant_color_combo_2_1} />
              <Text
                variant={'bold'}
                color={tenantData?.tenant_color_combo_2_1}
                text={staticInformation?.Headline_list_projects}
              />
              <Spacer position="bottom" size="15" />
            </>
          )}
          {otherNavItems.map((item, i) => (
            <MenuItem i={i} key={i} {...item} toggleMenu={toggleMenu} />
          ))}
        </>
      )}
    </motion.ul>
  );
}
