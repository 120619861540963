import { GET_TENANT_SUFFIX } from '../_constants/urls';
import { get } from './dataSource';

// loads a json with all information for the tenant
// including the name (navbar), the url to the picture (landing page)
// as well as the preferred color (bottom buttons)
const getTenantData = async (tenantId) => {
  return await get(`${GET_TENANT_SUFFIX}${tenantId}`, { params: {} });
};

export default getTenantData;
