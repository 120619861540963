import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function Loader({ description, ...restProps }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      margin="auto"
    >
      <CircularProgress {...restProps} />
      {description && (
        <Box mt={2}>
          <Typography align="center" variant="subtitle1">
            {description}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default Loader;
