import { GET_INFORMATION_STATIC_SUFFIX } from '../_constants/urls';
import { get } from './dataSource';

const getInformationStatic = async ({ langId }) => {
  return await get(`${GET_INFORMATION_STATIC_SUFFIX}`, {
    params: {
      lang_id: langId,
    },
  });
};

export default getInformationStatic;