import { ScreensActionTypes } from './screens.types';

const INITIAL_STATE = {
  recableId: null,
  showCameraButton: true,
  vizArtefacts: [],
  screenId: null,
  error: null,
};

export const screensReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ScreensActionTypes.SET_SCREEN_ID:
      return { ...state, screenId: action.payload };
    case ScreensActionTypes.SET_RECABLE_ID:
      return { ...state, recableId: action.payload };
    case ScreensActionTypes.SET_SHOW_CAMERA_BUTTON:
      return { ...state, showCameraButton: action.payload };
    case ScreensActionTypes.SET_VIZ_ARTEFACTS:
      return { ...state, vizArtefacts: action.payload };
    case ScreensActionTypes.SET_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
