import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { getText } from '../../utils/JsonMarkdownSerializer';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: ${(props) => props.background || 'none'};
  color: white;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`;

function LoadingScreen({ bgColor = null, ftColor = null, message = null }) {
  const staticInformation = useSelector(
    (state) => state.staticData.staticInformation
  );
  const tenantData = useSelector((state) => state.tenant.tenantData);

  return (
    <Wrapper background={bgColor ? bgColor : (tenantData ? tenantData.tenant_color_combo_3_0 : '#ffffff')}>
      <CircularProgress style={{ color: ftColor ? ftColor : (tenantData ? tenantData.tenant_color_combo_3_1 : '#000000') }} />
      <br />
      {getText({
        text: message ? message : (staticInformation ? staticInformation.Loading_message : 'Loading'),
        variant: 'bold',
        color: tenantData ? tenantData.tenant_color_combo_3_1 : '#000000'
      })}
    </Wrapper>
  );
}

export default LoadingScreen;
