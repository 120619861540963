import { GET_PROJECT_LIST_SUFFIX } from '../_constants/urls';
import { get } from './dataSource';

// get all projects
const getProjectList = async ({ langId, tenantId }) => {
  return await get(`${GET_PROJECT_LIST_SUFFIX}`, {
    params: {
      clientId: 'default',
      lang_id: langId,
      tenant_id: tenantId,
      is_hide_invisible_projects: 1,
    },
  });
};

export default getProjectList;
