import { StaticDataActionTypes } from './staticData.types';

const INITIAL_STATE = {
  staticInformation: null,
  languageCode: 1,
  error: null,
  languageDict: {},
};

export const staticDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StaticDataActionTypes.SET_STATIC_INFORMATION:
      return { ...state, staticInformation: action.payload };
    case StaticDataActionTypes.SET_LANGUAGE_CODE:
      return { ...state, languageCode: action.payload };
    case StaticDataActionTypes.SET_ERROR:
      return { ...state, error: action.payload };
    case StaticDataActionTypes.SET_LANGUAGE_DICT:
      return { ...state, languageDict: action.payload };
    default:
      return state;
  }
};
