import * as React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Text } from '../_reusable/Text';
import { useSelector } from 'react-redux';

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const navAnimation = {
  hover: {
    width: '100%',
    opacity: 1,
    transition: {
      duration: 0.4,
      type: 'tween',
      ease: 'easeIn',
    },
  },
  rest: {
    width: '0%',
    opacity: 0,
    ease: 'easeOut',
    duration: 0.2,
    type: 'tween',
  },
};

const NavLink = styled(motion.a)`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 8px;
`;

export function MenuItem({ i, text, page, toggleMenu }) {
  const navigate = useNavigate();
  const tenantData = useSelector((state) => state.tenant.tenantData);

  return (
    <motion.li
      variants={variants}
      whileTap={{ scale: 0.93 }}
      style={{
        listStyle: 'none',
      }}
    >
      <NavLink
        initial="rest"
        whileHover="hover"
        animate="rest"
        onClick={() => {
          navigate(page);
          toggleMenu();
        }}
      >
        <Text variant="bold" color="white" text={text} />
        <motion.div
          className="text-placeholder"
          style={{ border: `2px solid ${tenantData?.tenant_color_combo_2_2}` }}
          variants={navAnimation}
        />
      </NavLink>
    </motion.li>
  );
}
