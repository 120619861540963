import { NotificationManager } from 'react-notifications';

export const createNotification = (type, title, message = '') => {
  return () => {
    switch (type) {
      case 'info':
        NotificationManager.info(title);
        break;
      case 'success':
        NotificationManager.success(message, title);
        break;
      case 'warning':
        NotificationManager.warning(message, title, 3000);
        break;
      case 'error':
        NotificationManager.error(message, title, 5000);
        break;
      default:
        console.error('Notification type not recognized: ', type);
        break;
    }
  };
};
