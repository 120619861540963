import { combineReducers } from 'redux';
import { tenantReducer } from './tenant/tenant.reducer';
import { staticDataReducer } from './staticData/staticData.reducer';
import { projectReducer } from './project/project.reducer';
import { screensReducer } from './screens/screens.reducer';

export default combineReducers({
  tenant: tenantReducer,
  staticData: staticDataReducer,
  project: projectReducer,
  screens: screensReducer,
});
