import { TenantActionTypes } from './tenant.types';
import getTenant from '../../_api/getTenant';

export const setTenantData = (data) => ({
  type: TenantActionTypes.SET_TENANT_DATA,
  payload: data,
});

const setTenantFont = (font) => ({
  type: TenantActionTypes.SET_TENANT_FONT,
  payload: font,
});

export const setTenantLoadError = (err) => ({
  type: TenantActionTypes.SET_ERROR,
  payload: err,
});

export const loadTenant = () => async (dispatch) => {
  try {
    const res = await getTenant(process.env.REACT_APP_TENANT_ID || 1);
    dispatch(setTenantData(res.data.result));
    dispatch(setTenantFont(res.data.result.tenant_font_primary));
  } catch (err) {
    console.error(err);
    dispatch(
      setTenantLoadError({ message: `${err}`, status: err?.response?.status })
    );
  }
};
