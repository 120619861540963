import axios from 'axios';
import { AZURE_CODE, BASE_AZURE_API_URL } from '../_constants/urls';

const instance = axios.create({
  baseURL: BASE_AZURE_API_URL,
});

instance.defaults.headers.get['Content-Type'] = 'application/json';
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.get['x-functions-key'] = AZURE_CODE;
instance.defaults.headers.post['x-functions-key'] = AZURE_CODE;

export const get = async (api, config) =>
  await instance.get(api, {
    ...config,
    params: {
      ...config.params,
      session_id: window.localStorage.getItem('shaire_session_id'),
    },
  });

export const post = async (api, data, config) =>
  await instance.post(api, data, {
    ...config,
    params: {
      ...config.params,
      session_id: window.localStorage.getItem('shaire_session_id'),
    },
  });
