import React, { Suspense, useState, useRef, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { motion, useCycle } from 'framer-motion';
import TopNavigationBar from './TopNavigationBar';
import BottomCameraBar from './BottomCameraBar/index';
import Loader from './_reusable/Loader';

import { useDimensions } from '../_hooks/use-dimensions';
import { MenuToggle } from './Nav/MenuToggle';
import { Navigation } from './Nav/Navigation';
import './appLayout.css';
import { useDispatch, useSelector } from 'react-redux';
import { loadTenant } from '../redux/tenant/tenant.actions';
import LoadingScreen from './Camera/LoadingScreen';
import {
  loadLanguages,
  loadStaticInformation,
  setLanguageCode,
} from '../redux/staticData/staticData.actions';
import {
  clearProject,
  loadProject,
  loadProjectList,
  loadProjectRecables,
  setProjectId,
} from '../redux/project/project.actions';
import { loadScreen } from '../redux/screens/screens.actions';
import { FontDef } from './fontHelper';
import { useTheme } from '@mui/styles';
import { createNotification } from '../utils/notifications';

const MainContentWrapperDiv = styled.div`
  margin-bottom: 50px;
`;

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 260}px at 225px 30px)`,
    zIndex: 10,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: 'circle(25px at 225px 30px)',
    zIndex: 0,
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

function AppLayout() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { lang_code, projectParamId, screenParamId } = useParams();


  const containerRef = useRef(null);
  const [isOpen, toggleOpen] = useCycle(false, true);
  const { height } = useDimensions(containerRef);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const showCameraButton = useSelector(
    (state) => state.screens.showCameraButton
  );
  const projectData = useSelector((state) => state.project.projectData);
  const tenantData = useSelector((state) => state.tenant.tenantData);
  const tenantFont = useSelector((state) => state.tenant.font);
  const languageCode = useSelector((state) => state.staticData.languageCode);
  const staticInformation = useSelector(
    (state) => state.staticData.staticInformation
  );
  const projectId = useSelector((state) => state.project.projectId);

  const errorProject = useSelector((state) => state.project.error);
  const errorStatic = useSelector((state) => state.staticData.error);
  const errorTenant = useSelector((state) => state.tenant.error);
  const errorScreen = useSelector((state) => state.screens.error);

  // useEffect(() => {
  //   const loadFont = async () => {
  //     try {
  //       // load font here with web font loader
  //       await webFontLoader.load({
  //         google: ['roboto'],
  //       });
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  // }, [dispatch]);

  const setFontManual = (fontPrimary, fontSecondary) => {
    if (fontPrimary && fontSecondary) {
      const font = `"${fontPrimary.split('-').join(' ')}", "${fontSecondary
        .split('-')
        .join(' ')}", sans-serif`;
      theme.typography.fontFamily = font;
      theme.typography.body1.fontFamily = font;
      theme.typography.body2.fontFamily = font;
      theme.typography.button.fontFamily = font;
      theme.typography.caption.fontFamily = font;
      theme.typography.h1.fontFamily = font;
      theme.typography.h2.fontFamily = font;
      theme.typography.h3.fontFamily = font;
      theme.typography.h4.fontFamily = font;
      theme.typography.h5.fontFamily = font;
      theme.typography.h6.fontFamily = font;
      theme.typography.overline.fontFamily = font;
      theme.typography.subtitle1.fontFamily = font;
      theme.typography.subtitle2.fontFamily = font;
    }
  };

  useEffect(() => {
    if (tenantData) {
      setFontManual(
        tenantData.tenant_font_primary,
        tenantData.tenant_font_secondary
      );
    }
  }, [dispatch, tenantData]);

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      await dispatch(loadScreen(screenParamId));
      setIsLoading(false);
    };
    if (screenParamId) {
      load();
    }
  }, [dispatch, screenParamId]);

  useEffect(() => {
    if (
      (errorProject && errorProject.status === 404) ||
      (errorTenant && errorTenant.status === 404) ||
      (errorStatic && errorStatic.status === 404) ||
      (errorScreen && errorScreen.status === 404)
    ) {
      createNotification(
        'error',
        'NOT FOUND',
        'Requested resource not found.'
      )();
      navigate('/404');
    }
  }, [errorProject, errorTenant, errorStatic, errorScreen, dispatch]);

  useEffect(() => {
    if (projectParamId) dispatch(setProjectId(projectParamId));
  }, [projectParamId, dispatch]);

  useEffect(() => {
    if (lang_code > 0){
      dispatch(setLanguageCode(parseInt(lang_code)))
    } else {
      dispatch(setLanguageCode(1))
    };
  }, [lang_code, dispatch]);

  useEffect(() => {
    if (pathname === '/') {
      dispatch(setProjectId(null));
      dispatch(clearProject());
    }
  }, [dispatch, pathname]);

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      await dispatch(loadProjectList());
      await dispatch(loadProject(projectId));
      await dispatch(loadProjectRecables(projectId));
      setFontManual(tenantFont);
      setIsLoading(false);
    };
    if (projectId !== null) {
      load();
    }
  }, [dispatch, projectId, languageCode]);

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      await dispatch(loadStaticInformation());
      setIsLoading(false);
    };
    load();
  }, [dispatch, languageCode]);

  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      await dispatch(loadLanguages());
      await dispatch(loadStaticInformation());
      await dispatch(loadTenant());
      await dispatch(loadProjectList());
      setIsLoading(false);
    };
    load();
  }, [dispatch]);

  if (isLoading) {
    return (
      <LoadingScreen message={staticInformation?.Loading_message_webapp} />
    );
  }

  return (
    <FontDef font={tenantFont}>
      <TopNavigationBar />
      <motion.nav
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        custom={height}
        ref={containerRef}
        style={{
          position: 'fixed',
          overflowY: 'scroll',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          top: 0,
          right: 0,
          bottom: 0,
          width: isOpen ? '260px' : 'auto',
          // zIndex: isOpen ? 9 : 0,
          zIndex: 9,
          background: 'transparent',
        }}
      >
        {projectData?.project_is_navbar_visible && (
          <>
            <motion.div
              style={{
                position: 'fixed',
                top: 0,
                right: 0,
                bottom: 0,
                width: 260,
                background: tenantData?.tenant_color_combo_2_0,
              }}
              variants={sidebar}
            />
            <Navigation toggleMenu={() => toggleOpen()} />
            <MenuToggle toggle={() => toggleOpen()} />
          </>
        )}
      </motion.nav>

      <MainContentWrapperDiv>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </MainContentWrapperDiv>

      {!pathname.includes('/camera') &&
        !(pathname === '/') &&
        showCameraButton && projectData?.project_is_show_camera_bar && <BottomCameraBar />}
    </FontDef>
  );
}

export default AppLayout;
