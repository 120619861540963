import { TenantActionTypes } from './tenant.types';

const INITIAL_STATE = {
  tenantId: process.env.REACT_APP_TENANT_ID || 1,
  tenantData: null,
  font: null,
  error: null,
};

export const tenantReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TenantActionTypes.SET_TENANT_DATA:
      return { ...state, tenantData: action.payload };
    case TenantActionTypes.SET_ERROR:
      return { ...state, error: action.payload };
    case TenantActionTypes.SET_TENANT_FONT:
      return { ...state, font: action.payload };
    default:
      return state;
  }
};
