import { StaticDataActionTypes } from './staticData.types';
import getInformationStatic from '../../_api/getInformationStatic';
import getLanguageData from '../../_api/getLanguage';

export const setStaticInformation = (data) => ({
  type: StaticDataActionTypes.SET_STATIC_INFORMATION,
  payload: data,
});

export const setLanguageCode = (code) => ({
  type: StaticDataActionTypes.SET_LANGUAGE_CODE,
  payload: code,
});

export const setLoadStaticInformationError = (err) => ({
  type: StaticDataActionTypes.SET_ERROR,
  payload: err,
});

export const setLanguageDict = (dict) => ({
  type: StaticDataActionTypes.SET_LANGUAGE_DICT,
  payload: dict,
});

export const loadLanguages = () => async (dispatch, getState) => {
  try {
    const res = await getLanguageData();
    const _languages = {};
    res.data.result.map((lang) => {
      _languages[`${lang.lang_name}`] = lang.lang_id;
    });
    await dispatch(setLanguageDict({ ..._languages }));
  } catch (err) {
    console.log(err);
  }
};

export const loadStaticInformation = () => async (dispatch, getState) => {
  try {
    const { languageCode } = getState().staticData;
    const info = languageCode
      ? (await getInformationStatic({ langId: languageCode })).data.result
      : [];
    await dispatch(setStaticInformation({ ...info }));
  } catch (err) {
    console.error(err);
    dispatch(
      setLoadStaticInformationError({
        message: `${err}`,
        status: err?.response?.status,
      })
    );
  }
};
