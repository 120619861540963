import { ScreensActionTypes } from './screens.types';
import getRecableScreens from '../../_api/getRecableScreens';
import getVizRecable from '../../_api/getVizRecable';
import { processArtefact } from '../../components/Recable/RecableScreenParser';

export const setScreenId = (id) => ({
  type: ScreensActionTypes.SET_SCREEN_ID,
  payload: id,
});

export const setScreenRecableId = (id) => ({
  type: ScreensActionTypes.SET_RECABLE_ID,
  payload: id,
});

export const setScreenShowCameraButton = (show) => ({
  type: ScreensActionTypes.SET_SHOW_CAMERA_BUTTON,
  payload: show,
});

export const setScreenVizArtefacts = (artefacts) => ({
  type: ScreensActionTypes.SET_VIZ_ARTEFACTS,
  payload: artefacts,
});

export const setScreenLoadError = (err) => ({
  type: ScreensActionTypes.SET_ERROR,
  payload: err,
});

export const loadScreen = (screenParamId) => async (dispatch, getState) => {
  try {
    const { languageCode } = getState().staticData;
    await dispatch(setScreenShowCameraButton(true));

    const res = await getRecableScreens({ screenId: screenParamId });
    if (res.data.result.length > 0) {
      dispatch(
        setScreenShowCameraButton(
          res.data.result[0].screen_is_show_camera_button
        )
      );
      const viz = await getVizRecable({
        langId: languageCode,
        screenId: screenParamId,
      });
      if (viz.status === 200) {
        const processedArtefacts = viz.data.result.map((res) =>
          processArtefact(res)
        );
        dispatch(setScreenVizArtefacts(processedArtefacts));
      }
    }
  } catch (err) {
    console.error(err);
    dispatch(
      setScreenLoadError({ message: `${err}`, status: err?.response?.status })
    );
  }
};
